import { Breadcrumbs, Anchor, Group, Stack, Text, Button, Alert } from '@mantine/core';
import { IconChevronLeft, IconInfoCircle, IconLogout, IconPlus } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import classes from './../../styles/HeadPage.module.css';
import { useState } from 'react';

interface Props {
    page: string;
    links: {title: string, href: string}[]
    labelCreate?: string;
    labelExport: string;
    onCreate: () => void;
    onExport: () => void;

    hiddenExport?: boolean;
}

export function HeadPage({page, links, labelCreate, labelExport, onCreate, onExport, hiddenExport}: Props) {
    const [showAlert, setShowAlert] = useState(true);
    let location = useLocation();

    
    const openModal = () => {
        console.log("openModal");
        
        onCreate()
    };

    return (
        <Stack gap={10}>
            {showAlert && !location.pathname.includes("/dashboard/settings")
                ? <Alert 
                    p={10} variant="light" color="red" withCloseButton
                    onClose={() => setShowAlert(false)}
                    title="هذه النسخة تجريبية , سيتم تجديد البيانات عند الساعة 12 ليلا." icon={<IconInfoCircle />}
                />
                : null
            }

            <Group justify='space-between' align='flex-end' mb={20} >
                <Stack gap={5}>
                    <Text size='24px' c={"#333843"} fw={600}>{page}</Text>
                    <Breadcrumbs separator={<IconChevronLeft size={16}/>} separatorMargin={3} >
                        {links.map((item, index) => (
                            index !== links.length - 1
                                ? <Link 
                                    key={index} to={item.href} className={classes.link}
                                    data-active={index !== links.length - 1 ? true : undefined}
                                >
                                    {item.title}
                                </Link>
                                : <Text key={index} className={classes.lastLink}>{item.title}</Text>
                        ))}
                    </Breadcrumbs>
                </Stack>
                <Group justify='space-between' align='flex-end' gap={5}>
                    {!hiddenExport
                        ? <Button 
                            variant={labelCreate ? "transparent" : "filled"}
                            color={'#323232'} px={10}
                            leftSection={<IconLogout style={{transform: "rotate(-90deg)"}} size={18}/>}
                            onClick={() => onExport()}
                        >
                            {labelExport}
                        </Button>
                        : null
                    }
                    
                    {labelCreate
                        ? <Button
                            variant="filled" color={'#323232'} px={10}
                            leftSection={<IconPlus size={18}/>}
                            onClick={() => onCreate()}
                        >
                            {labelCreate}
                        </Button>
                        : null
                    }
                </Group>
            </Group>
        </Stack>
    );
}