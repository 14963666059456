import {Group, Box, Burger, Image, ActionIcon, Indicator, useDirection, Menu, useMantineTheme, Alert, Text, Avatar, Button } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Emitter from '../../lib/Emitter';
import { IconBell, IconLanguage } from '@tabler/icons-react';
import { useAudio } from 'react-use-audio';
//@ts-ignore
import notificationFile from "../../Notification.mp3";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
const cookies = new Cookies(null, { path: '/' });

export function Navbar ({sideBarOpened, toggleSideBar}: {sideBarOpened: boolean; toggleSideBar: () => void}) {
    let location = useLocation();
    let them = useMantineTheme();
    const navigate = useNavigate();
    const [notification, setNotification] = useState(false);
    const [numberUsers, setNumberUsers] = useState(0);
    const [remainingTime, setRemainingTime] = useState("");
    const { play, stop, data } = useAudio(notificationFile);
    const { t, i18n, ready } = useTranslation();
    const { dir, setDirection } = useDirection();
    const matches = useMediaQuery('(max-width: 48em)');
    
    useEffect(() => {
        Emitter.on("newOrder", (data: any) => {
            play();
            setNotification(true)
        })
        Emitter.on("numberUsers", (data: any) => {
            setNumberUsers(data)
        })
    }, [])

    useEffect(() => {
        const dateB = dayjs(cookies.get('dateTesting')).add(3, 'days')
        const remainingTime = dateB.diff(new Date(), 'hours')
        setRemainingTime(`${remainingTime} ساعة`)

        setInterval(() => {
            const remainingTime = dateB.diff(new Date(), 'hours')
            setRemainingTime(`${remainingTime} ساعة`)
        },  3600 * 1000)
    }, [])

    
    useEffect(() => {
        if (parseInt(remainingTime.split(" ")[0]) <= 0) {
            cookies.remove('token');
            cookies.remove('id');
            cookies.remove('name');
            cookies.remove('email');
            cookies.remove('role');
            cookies.remove('dateTesting');
            navigate("/");
        }
    }, [remainingTime])
    
    const onChangeLang = (lang: string) => {
        setDirection(i18n.dir(lang));
        i18n.changeLanguage(lang);
        cookies.set('lang', lang);
    }

    return (
        <Box p={0} m={0} h={matches ? 150 : 100} display={'flex'} style={{flexDirection: "column"}}>
            <Alert
                h={matches ? 90 : 40} style={{padding: 0}}
                color="#000" variant='filled' radius={0}
                styles={{
                    root: {border: 0},
                    title: {justifyContent: 'center', height: matches ? 90 : 40, width: "100%"},
                    label: {width: "100%"}
                }}
                title={
                    <Group justify='space-around' w={"100%"}>
                        <Group gap={5}>
                            <Text size={matches ? "10px" : 'sm'}>مستخدمين يجربون الان :</Text>
                            
                            <Avatar.Group>
                                {numberUsers == 1
                                    ? <Avatar size={matches ? "xs" : "sm"}>+{numberUsers}</Avatar>
                                    : numberUsers == 2
                                        ? <>
                                            <Avatar size={matches ? "xs" : "sm"} src="image.png" />
                                            <Avatar size={matches ? "xs" : "sm"}>+{numberUsers}</Avatar>
                                        </>
                                        :  numberUsers >= 3
                                            ? <>
                                                <Avatar size={matches ? "xs" : "sm"} src="image.png" />
                                                <Avatar size={matches ? "xs" : "sm"} src="image.png" />
                                                <Avatar size={matches ? "xs" : "sm"}>+{numberUsers}</Avatar>
                                            </>
                                            : <></>
                                }
                            </Avatar.Group>
                        </Group>

                        <Text size={matches ? "10px" : 'sm'}>باقي على انتهاء نسختك التجريبية : {remainingTime}</Text>

                        <Button
                            variant="filled" color="#fcd008" size="xs" radius="xs" c={"#000"}
                            component='a' target='_blank' href='https://wa.me/213674014619'
                        >شراء الان</Button>
                    </Group>
                }
            />
            <Group justify="space-between" h={60} w="100%" wrap='nowrap' gap={0}>
                {!sideBarOpened
                    ? <Group justify='center' align='center' h={60} miw={220} visibleFrom='md' style={{borderLeft: "1px solid #dee2e6", borderBottom: "1px solid #fff"}}>
                        <Image src={"/Bi3li_logo.png"} fit='contain' height={45} width={"auto"} />
                    </Group>
                    : null
                }

                <Group justify="space-between" align='center' h="100%" w="100%" px={{ base: "xs", sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} >
                    <Burger opened={false} onClick={toggleSideBar} size={'sm'} />

                    <Image src={"/Bi3li_logo.png"} fit='contain' height={45} width={"auto"} hiddenFrom='md' />

                    <Group justify="flex-end" align='center' h="100%">
                        <Menu shadow="md" width={120}>
                            <Menu.Target>
                                <ActionIcon variant="transparent" color={them.colors.gray[7]}>
                                    <IconLanguage size={25} />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={() => onChangeLang("en")}>{t('listLanguages.english')}</Menu.Item>
                                <Menu.Item onClick={() => onChangeLang("ar")}>{t('listLanguages.arabic')}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                        <Indicator inline processing color="red" size={10} h={28} disabled={!notification}>
                            <ActionIcon
                                variant="transparent" color={them.colors.gray[7]}
                                onClick={() => {
                                    navigate("/dashboard/orders");
                                    setNotification(false)
                                }}
                            >
                                <IconBell size={25} stroke={1.5} />
                            </ActionIcon>
                        </Indicator>
                    </Group>
                </Group>
            </Group>
        </Box>
    );
}